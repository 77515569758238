<template>
  <XModal
    :class="$style.expiredModal"
    :is-open="show"
    v-bind="styleClasses"
    :width="725"
    data-testid="trialExpiredModal"
    @close="$emit('close')"
  >
    <template #header>
      {{ $t('accountModule.modalTrialExpired.title') }}
    </template>

    <div :class="$style.textContent">
      <div :class="$style.notification">
        <XIcon
          icon="clock"
          size="2x"
        />
        <h4 :class="$style.mobileTitle">
          {{ $t('accountModule.modalTrialExpired.content.subtitle') }}
        </h4>
        <h2 :class="$style.desktopTitle">
          {{ $t('accountModule.modalTrialExpired.content.subtitle') }}
        </h2>
      </div>

      <p>
        {{ $t('accountModule.modalTrialExpired.content.timeSpent') }}
      </p>
      <p>
        {{ $t('accountModule.modalTrialExpired.content.choosePlan') }}
      </p>
    </div>

    <img
      src="@account/assets/images/premium/person-trial-expired-image.png"
      :alt="$t('accountModule.modalTrialExpired.imageAlt')"
      :class="$style.assets"
    />

    <template #actions>
      <XButton
        :class="$style.plansButton"
        data-testid="plansBtn"
        @click="$goTo(plansLink)"
      >
        {{ $t('accountModule.modalTrialExpired.purchase') }}
      </XButton>
    </template>
  </XModal>
</template>

<script>
import { defineComponent, computed, useCssModule } from 'vue';

export default defineComponent({
  name: 'TrialExpiredModal',
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    plansLink: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  setup() {
    const style = useCssModule();

    const styleClasses = computed(() => ({
      modalClasses: [style.modal],
      headerClasses: [style.header],
      contentClasses: [style.content],
      actionsClasses: [style.actions],
    }));

    return {
      styleClasses,
    };
  },
});
</script>

<style lang="scss" module>
.expiredModal {
  .header {
    width: 100%;
    margin-bottom: 0;
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-regular);
    color: var(--color-neutral-800);
  }

  .modal {
    gap: var(--space-regular);

    > .content {
      .desktopTitle,
      .mobileTitle {
        margin: 0;
      }
    }
  }
}

.textContent {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.notification {
  display: flex;
  align-items: center;
  margin-bottom: var(--space-regular);
  padding: var(--space-medium);
  gap: var(--space-small-xx);
  color: var(--color-brand-primary-400);
  background-color: var(--color-neutral-50);
  border-radius: var(--space-small-xx);

  .desktopTitle {
    display: none;
  }

  .mobileTitle {
    display: inline;
  }
}

.assets {
  display: none;
}

@include breakpoint('medium') {
  .expiredModal {
    .modal {
      gap: unset;
    }

    .actions {
      justify-content: flex-start;
    }
  }

  .content {
    display: grid;
    justify-content: space-between;
    grid-template: auto / 1fr 0.5fr;
  }

  .notification {
    .desktopTitle {
      display: inline;
    }

    .mobileTitle {
      display: none;
    }
  }

  .assets {
    display: block;
    width: 213px;
    height: 268px;
    margin: auto;
  }
}
</style>
